<template>
  <v-container>

    <v-card width="500" class="mt-12">
      <v-card-title>
        <h2>Inloggen</h2>
      </v-card-title>
      <v-card-text>
        <v-form onsubmit="return false;">
          <v-text-field
            name="username"
            maxlength="200"
            label="Gebruikersnaam"
            v-model="username"
            prepend-icon="mdi-account-circle"
            autofocus
          ></v-text-field>
          <v-text-field
            name="password"
            type="password"
            maxlength="1024"
            label="Wachtwoord"
            v-model="password"
            prepend-icon="mdi-lock"
          ></v-text-field>
          <v-alert type="error" v-model="showerrormessage" dismissible class="mt-4">{{
            errormessage
          }}</v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn to="PasswordReset" small>Wachtwoord vergeten</v-btn>
        <v-spacer />
        <v-btn type="submit" @click="login()" color="primary">Inloggen</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginOk: false,
      loginFailed: false,
      username: "",
      password: "",
      settings: null,
      errormessage: null,
      showerrormessage: false,
    };
  },
  methods: {
    loaddata: function () {
      this.$api().Logout().then(() => {
        this.$root.$emit("login");
      });
    },
    login: function () {
      this.$api()
        .Login({ Username: this.username, Password: this.password })
        .then(() => {
          // Inloggen gelukt
          this.$root.$emit("login");
          // Doorsturen naar ReturnUrl
          const url = this.$route.query.ReturnUrl;
          if (url) {
            this.$router.push(url);
          } else {
            this.$router.push({ name: "beheer" });
          }
        })
        .catch(() => {
          this.errormessage = "Inloggen mislukt.";
          this.showerrormessage = true;
        });
    },
  },
  watch: {
    username: function () {
      this.showerrormessage = false;
    },
    password: function () {
      this.showerrormessage = false;
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>

<style scoped>
p {
  line-height: 1.8;
}
</style>
