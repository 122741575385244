<template>
  <div>
    <h2>Aanmelden vragenlijst ({{ modus }})</h2>

    <v-select
      label="Kies een school uit de lijst"
      :items="scholen"
      v-model="school"
      item-value="Id"
      item-text="Naam"
      return-object
      :error="schoolvalidate.error"
      :error-messages="schoolvalidate.message"
    >
    </v-select>
    <v-select
      label="Kies een klas uit de lijst"
      :items="klassen"
      v-model="klas"
      item-value="Id"
      item-text="Naam"
      return-object
      :error="klasvalidate.error"
      :error-messages="klasvalidate.message"
      no-data-text="Geen klassen voor deze school"
    >
    </v-select>

    <v-text-field
      label="Wat is je functie? (niet verplicht)"
      v-model="functie"
      :error="functievalidate.error"
      :error-messages="functievalidate.message"
      maxlength="100"
    >
    </v-text-field>

    <v-btn color="primary" @click="startsessie()" class="mt-2"
      >Start de vragenlijst
    </v-btn>
  </div>
</template>


<script>
export default {
  name: "startsessieDocent",
  components: {},
  props: ["modus"],
  data() {
    return {
      scholen: [],
      school: null,
      klassen: [],
      klas: null,
      functie: null,
      functievalidate: { error: false, message: "" },
      schoolvalidate: { error: false, message: "" },
      klasvalidate: { error: false, message: "" },
    };
  },
  methods: {
    getscholen: function () {
      this.$api().pScholenGet().then((response) => {
        this.scholen = response.data;
      });
    },
    startsessie: function () {
      if (this.school == null)
      {
          this.schoolvalidate.error = true;
          this.schoolvalidate.message = "Kies een school";
          return
      }
      if (this.klas == null)
      {
          this.klasvalidate.error = true;
          this.klasvalidate.message = "Kies een klas";
          return
      }

      this.$api()
        .pSessieStart({
          IconId: null,
          KlasId: this.klas.Id,
          Leeftijd: null,
          Geslacht: null,
          Modus: this.modus,
          Functie: this.functie,
        })
        .then((response) => {
          var sessiecode = response.data[0].SessieCode;
          if (sessiecode) {
            this.$router.push({
              name: "vragenlijst",
              params: { code: sessiecode },
            });
          }
        });
    },
  },
  watch: {
    school: function () {
      this.schoolvalidate.message = "";
      this.schoolvalidate.error = false;
       if (this.school != null) {
        this.$api()
          .pKlassenGet({ SchoolId: this.school.Id })
          .then((response) => {
            this.klassen = response.data;
            this.klas = null;
          })
          .catch(() => {});
      } else {
        this.klassen = [];
        this.klas = null;
      }
    },
    klas: function () {
      this.klasvalidate.message = "";
      this.klasvalidate.error = false;
    },
    functie: function () {
      this.functievalidate.message = "";
      this.functievalidate.error = false;
    },
  },
  created: function () {
    this.getscholen();
  },
};
</script>


<style scoped>
</style>
