/*
    Generated file
    Do not alter.
    30-07-2022 22:36:09
 */
import axios from 'axios';


export default {
    install(Vue) {

        let baseurl = "";
        if (process.env.NODE_ENV == "development")
            baseurl = 'http://localhost:8080/api/Sproc/';
        else
            baseurl = '/api/Sproc/';

        Vue.$api =
        {
            axios: axios,
            apiUrl: baseurl,
            config: null,
            _updateHeaders: function() {
                Vue.$api.config = {
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + sessionStorage.getItem("session-id")
                    },
                    baseURL: Vue.$api.apiUrl,
                    withCredentials: true,
                };
                Vue.$api.configBlob = {
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + sessionStorage.getItem("session-id")
                    },
                    baseURL: Vue.$api.apiUrl,
                    withCredentials: true,
                    responseType: 'blob',
                };
            }.bind(this),

        }
        Vue.$api._updateHeaders();

        Vue.prototype.$api = function () {
            return {

                updateHeaders: function () {
                    Vue.$api._updateHeaders();
                },
                getBaseUrl: function () {
                    return Vue.$api.apiUrl;
                },
                get: function (url) {
                    return new Promise((resolve, reject) => {
                        Vue.$api.axios.get(url)
                            .then((response) => {
                                if (response.status === 200) {
                                    resolve(response);
                                } else {
                                    reject('Bad request ' + response.code.toString());
                                }
                            })
                            .catch((err) => {
                                if (err.message === 'Network Error') {
                                    this.$root.$emit('offline');
                                    reject('Network Error');
                                }
                                else {
                                    reject('Server error');
                                }
                            });
                    });
                }.bind(this),

    // pAntwoordSave
    pAntwoordSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspAntwoordSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pControleerIcon
    pControleerIcon: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspControleerIcon' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pIconsGet
    pIconsGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspIconsGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pKlasDelete
    pKlasDelete: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspKlasDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pKlasSave
    pKlasSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspKlasSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pKlassenGet
    pKlassenGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspKlassenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pRapportDocenten
    pRapportDocenten: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspRapportDocenten' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pRapportLeerlingen
    pRapportLeerlingen: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspRapportLeerlingen' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pScholenGet
    pScholenGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspScholenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pSchoolDelete
    pSchoolDelete: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspSchoolDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pSchoolSave
    pSchoolSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspSchoolSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pSessieStart
    pSessieStart: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspSessieStart' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // pVragenlijstGet
    pVragenlijstGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('sspVragenlijstGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Login
    Login: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('Login' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Logout
    Logout: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserLogout' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // RequestPasswordReset
    RequestPasswordReset: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('RequestPasswordReset' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // PasswordReset
    PasswordReset: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('PasswordReset' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Authenticated
    Authenticated: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pAuthenticated' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // ValidationRulesGet
    ValidationRulesGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pValidationRulesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserGetList
    UserGetList: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserGetList' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserInitNew
    UserInitNew: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserInitNew' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserGetDetails
    UserGetDetails: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserGetDetails' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserSave
    UserSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserValidate
    UserValidate: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserValidate' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // NewUserMail
    NewUserMail: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('NewUserMail' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // SecurityAreasGet
    SecurityAreasGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pSecurityAreasGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),



                saveDownload: function(data, filename) {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }.bind(this),

            }
        }
    }
};
