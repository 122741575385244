import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import ApiHandler from './ApiHandler'
import Dayjs from 'vue-dayjs';

Vue.use(ApiHandler);

Vue.config.productionTip = false

Vue.use(Dayjs, {
  lang:  'nl',
  filters: {
    ago: 'ago',
  },
  directives: {
    countdown: 'countdown',
  }
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
