<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      
        <v-img
          alt="Vigo logo"
          class="shrink mt-1 hidden-xs-and-down"
          contain
          src="/Logo_Vigo.svg"
          max-width="60"
          @click="home"
        />
      
      <h3 class="ml-3 mt-7">#Taboeiend Experience</h3>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  methods: {
    home: function () {
      this.$router.push({ name: "home" });
    },
  },

  data: () => ({
    //
  }),
};
</script>
