<template>
  <v-container>
    <v-card max-width="500">
      <v-card-title>Download gegevens</v-card-title>
      <v-card-text>
        <p>Download ingevoerde gegevens als excel-bestand.</p>
        <v-btn @click="downloadLeerlingen()"
          ><v-icon left>mdi-file-excel-outline</v-icon>Leerlingen</v-btn
        >
        <v-btn class="ml-2" @click="downloadDocenten()"
          ><v-icon left>mdi-file-excel-outline</v-icon>Docenten</v-btn
        >
      </v-card-text>
      <v-progress-linear indeterminate v-show="bezig"></v-progress-linear>
    </v-card>
    <div>
      <v-btn class="mt-6" outlined :to="{ name: 'beheer' }">Terug</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "overzicht",
  components: {},
  data: function () {
    return {
      bezig: false,
    };
  },
  methods: {
    loaddata: function () {},
    downloadLeerlingen: function () {
      this.bezig = true;
      this.$api()
        .pRapportLeerlingen({}, "excel")
        .then((response) => {
          this.bezig = false;
          this.$api().saveDownload(response.data, "leerlingen.xlsx");
        })
        .catch(() => {});
    },
    downloadDocenten: function () {
      this.bezig = true;
      this.$api()
        .pRapportDocenten({}, "excel")
        .then((response) => {
          this.bezig = false;
          this.$api().saveDownload(response.data, "docenten.xlsx");
        })
        .catch(() => {});
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>
