<template>
  <div>
    <v-card max-width="500">
      <v-card-title class="justify-center">Selecteer een meting</v-card-title>
      <v-card-text>
        <v-btn
          color="primary"
          block
          class="mt-5 mb-5"
          :to="{ name: 'startvoormeting' }"
          >Voormeting</v-btn
        >
        <v-btn
          color="primary"
          block
          class="mt-5 mb-5"
          :to="{ name: 'startnameting' }"
          >Nameting</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>