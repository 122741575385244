<template>
  <div>
    <h1 v-if="vragenlijst">
      <img
        :src="'/icons/' + vragenlijst.Icon"
        max-width="50"
        max-height="50"
        contain
        position="center"
        v-if="vragenlijst && vragenlijst.Naam != 'Docenten'"
        height="30"
      />
      {{ vragenlijst.Naam }}
    </h1>

    <v-card v-if="vraag" max-width="800">
      <v-card-text style="min-height: 400px">
        <v-row no-gutters v-show="vraag.Vraaggroep" class="text-h5">{{
          vraag.Vraaggroep
        }}</v-row>
        <v-row
          no-gutters
          v-show="vraag.VraaggroepToelichting"
          class="text-caption mb-2"
        >
          {{ vraag.VraaggroepToelichting }}
        </v-row>
        <v-divider class="mt-4 mb-4" v-show="vraag.Vraaggroep"></v-divider>
        <v-row
          no-gutters
          style="word-break: normal"
          class="text-h5 font-weight-bold"
        >
          {{ vraag.Nummer }}. {{ vraag.Tekst }}
        </v-row>
        {{ vraag.Toelichting }}
        <div v-if="vraag.Type == 'waar/nietwaar'">
          <v-radio-group
            v-model="antwoord"
            :col="$vuetify.breakpoint.xs"
            :row="$vuetify.breakpoint.smAndUp"
          >
            <v-radio value="niet-waar" label="Niet waar"></v-radio>
            <v-radio value="beetje-waar" label="Beetje waar"></v-radio>
            <v-radio value="zeker-waar" label="Zeker waar"></v-radio>
          </v-radio-group>
        </div>
        <div v-if="vraag.Type == '0-100'">
          <v-slider
            v-model="antwoord"
            label=""
            thumb-color="primary"
            thumb-label="always"
            :min="0"
            :max="100"
            class="mt-10"
          ></v-slider>
        </div>
        <div v-if="vraag.Type == 'open'">
          <v-textarea
            solo
            outlined
            v-model="antwoord"
            class="text-h5"
            counter
            autofocus
            maxlength="500"
          >
          </v-textarea>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="volgende(false)"
          color="primary"
          :disabled="antwoord == null"
          >Volgende vraag</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="volgende(true)" small text v-if="false"
          >Vraag overslaan</v-btn
        >
      </v-card-actions>
      <v-progress-linear
        :value="progress"
        v-if="vragenlijst && vragenlijst.Vraag.length > 0"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "vragenlijst",
  components: {},
  props: ["modus"],
  data() {
    return {
      vragenlijst: null,
      vraag: null,
      vraagidx: 0,
      antwoord: null,
    };
  },
  methods: {
    getvragenlijst: function () {
      this.$api()
        .pVragenlijstGet({ Code: this.$route.params.code })
        .then((response) => {
          this.vragenlijst = response.data[0];
          if (this.vragenlijst.Vraag.length > 0) {
            this.vraagidx = 0;
            this.vraag = this.vragenlijst.Vraag[this.vraagidx];
            if (this.vraag.Type == "0-100") this.antwoord = 50;
            if (this.vraag.Type == "open") this.antwoord = "";
          }
        });
    },
    volgende: function (overslaan) {
      if (overslaan) this.antwoord = "overgeslagen";
      this.saveAntwoord();
      if (this.vraagidx < this.vragenlijst.Vraag.length - 1) {
        this.vraagidx++;
        this.vraag = this.vragenlijst.Vraag[this.vraagidx];
        this.antwoord = null;
        if (this.vraag.Type == "0-100") this.antwoord = 50;
        if (this.vraag.Type == "open") this.antwoord = "";
      } else {
        this.$router.push({ name: "einde" });
      }
    },
    saveAntwoord: function () {
      this.$api()
        .pAntwoordSave({
          Sessie: this.$route.params.code,
          Vraag: this.vraag.Code,
          Antwoord: this.antwoord,
        })
        .then((response) => {
          return response.data[0].Success;
        });
    },
  },
  computed: {
    progress: function () {
      if (this.vragenlijst) {
        if (this.vragenlijst.Vraag.length > 0) {
          return (this.vraagidx / this.vragenlijst.Vraag.length) * 100.0;
        } else return 0;
      } else return 0;
    },
  },
  watch: {},
  created: function () {
    this.getvragenlijst();
  },
};
</script>


<style scoped>
</style>
