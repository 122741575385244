<template>
  <v-container>
    <v-card max-width="500">
      <v-list>
        <v-subheader>taboeiend-experience.nl beheer</v-subheader>
        <v-list-item
          :to="{ name: 'beheerscholen' }"
          v-if="rechten.BeheerSchoolKlas"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-chair-school </v-icon>
          </v-list-item-icon>
          <v-list-item-content>Beheer scholen en klassen</v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'overzicht' }"
          v-if="rechten.DownloadResult"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-download </v-icon>
          </v-list-item-icon>
          <v-list-item-content>Download overzichten</v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'users' }" v-if="rechten.UserAdmin">
          <v-list-item-icon>
            <v-icon color="primary">mdi-account-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-content>Accountbeheer</v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'login' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-logout </v-icon>
          </v-list-item-icon>
          <v-list-item-content>Uitloggen</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "beheer",
  components: {},
  data: function () {
    return {
      rechten: [],
    };
  },
  methods: {
    loaddata: function () {
      this.$api()
        .SecurityAreasGet()
        .then((response) => {
          this.rechten = response.data;
        });
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>
