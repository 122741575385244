import Vue from 'vue'
import Router from 'vue-router'
import StartSessie from '@/components/startsessie.vue'
import StartSessieDocent from '@/components/startsessieDocent.vue'
import Vragenlijst from '@/components/vragenlijst.vue'
import Einde from '@/components/einde.vue'
import Home from '@/components/home.vue'
import Beheer from '@/components/beheer.vue'
import BeheerScholenEnKlassen from '@/components/beheerschoolklas.vue'
import Overzicht from '@/components/overzicht.vue'

import Login from '@/components/useradmin/Login.vue'
import PasswordReset from '@/components/useradmin/PasswordReset.vue'
import Activate from '@/components/useradmin/Activate.vue'
import Users from '@/components/useradmin/Users.vue'
import Roles from '@/components/useradmin/Roles.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/voormeting',
      name: 'startvoormeting',
      meta: {
        auth: false
      },
      props: { modus: "voormeting" },
      component: StartSessie,
    },
    {
      path: '/nameting',
      name: 'startnameting',
      meta: {
        auth: false
      },
      props: { modus: "nameting" },
      component: StartSessie,
    },
    {
      path: '/docent',
      name: 'startdocent',
      meta: {
        auth: false
      },
      props: { modus: "docent" },
      component: StartSessieDocent,
    },
    {
      path: '/vragenlijst/:code',
      name: 'vragenlijst',
      meta: {
        auth: false
      },
      component: Vragenlijst,
    },
    {
      path: '/klaar',
      name: 'einde',
      meta: {
        auth: false
      },
      component: Einde,
    },
    {
      path: '/beheer',
      name: 'beheer',
      component: Beheer,
      meta: { auth: true }
    },
    {
      path: '/beheer/scholenklassen',
      name: 'beheerscholen',
      meta: {
        auth: true
      },
      component: BeheerScholenEnKlassen,
    },
    {
      path: '/',
      name: 'home',
      meta: {
        auth: false
      },
      component: Home,
    },
    {
      path: '/beheer/gebruikers',
      name: 'users',
      component: Users,
      meta: { auth: true }
    },
    {
      path: '/beheer/rollen',
      name: 'roles',
      component: Roles,
      meta: { auth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { auth: false }
    },
    {
      path: '/passwordreset',
      name: 'passwordreset',
      component: PasswordReset,
      meta: { auth: false }
    },
    {
      path: '/activeer',
      name: 'activeer',
      component: Activate,
      meta: { auth: false }
    },
    {
      path: '/overzicht',
      name: 'overzicht',
      component: Overzicht,
      meta: { auth: true }
    },

  ]
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.auth)) {
    // Authenticatie vereist. Check of session-cookie nog geldig is.
    var auth = false;
    router.app.$api().Authenticated() // Vraag server of sessie-id nog geldig is.
      .then((response) => {
        auth = (response.data.length > 0);
        if (auth) {
          // Sessie-id is nog geldig
          next();
        }
        else {
          // Sessie-id is verlopen => inlogscherm
          next({ name: 'login', query: { ReturnUrl: to.fullPath } });
        }
      })
      .catch(() => {
        // Call naar Authenticated faalt => inlogscherm
        next({ name: 'login', query: { ReturnUrl: to.fullPath } });
      });
  }
  else {
    next();
  }
});

export default router;