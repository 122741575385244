<template>
  <div>
    <h2>Beheer scholen en klassen</h2>
    <div v-show="school == null">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">School</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in scholen"
              :key="item.Id"
              @click="editSchool(item)"
            >
              <td>{{ item.Naam }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn color="primary" @click="addSchool">School toevoegen</v-btn>
      <div>
        <v-btn class="mt-6" outlined :to="{ name: 'beheer' }">Terug</v-btn>
      </div>
    </div>
    <v-card v-if="school != null && klas == null">
      <v-card-title>Details van school</v-card-title>
      <v-card-text
        ><v-text-field
          v-model="school.Naam"
          label="Naam van de school"
          maxlength="200"
        ></v-text-field>
        <v-simple-table v-show="school.Id != null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Klassen van {{ school.Naam }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in klassen" :key="item.Id" @click="klas = item">
                <td>{{ item.Naam }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn small color="primary" @click="addKlas" v-show="school.Id != null"
          >Klas toevoegen</v-btn
        >
        <p v-show="school.Id == null">
          Sla de nieuwe school op om klassen toe te kunnen voegen.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveSchool" color="primary">Opslaan</v-btn>
        <v-btn @click="school = null">Annuleren</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="deleteSchool" color="error">Verwijder school</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="klas != null">
      <v-card-title>Details van klas</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="school.Naam"
          readonly
          label="Geselecteerde school"
        ></v-text-field>
        <v-text-field
          v-model="klas.Naam"
          label="Naam van de klas"
          maxlength="200"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveKlas" color="primary">Opslaan</v-btn>
        <v-btn @click="klas = null">Annuleren</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="deleteKlas" color="error">Verwijder klas</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>


<script>
export default {
  name: "beheerschoolklas",
  components: {},
  data() {
    return {
      scholen: [],
      school: null,
      klassen: [],
      klas: null,
    };
  },
  methods: {
    getscholen: function () {
      this.$api()
        .pScholenGet()
        .then((response) => {
          this.scholen = response.data;
        });
    },
    getklassen: function () {
      this.$api()
        .pKlassenGet({ SchoolId: this.school.Id })
        .then((response) => {
          this.klassen = response.data;
        })
        .catch(() => {});
    },
    addSchool: function () {
      this.school = { Id: null, Naam: "" };
      this.klassen = [];
      this.klas = null;
    },
    editSchool: function (aschool) {
      this.school = aschool;
      this.klas = null;
      this.getklassen();
    },
    saveSchool: function () {
      this.$api()
        .pSchoolSave({ SchoolId: this.school.Id, Naam: this.school.Naam })
        .then(() => {
          this.school = null;
          this.getscholen();
        })
        .catch(() => {});
    },
    deleteSchool: function () {
      if (confirm("Weet je zeker dat je deze school wit verwijderen?")) {
        this.$api()
          .pSchoolDelete({ SchoolId: this.school.Id })
          .then(() => {
            this.school = null;
            this.getscholen();
          })
          .catch(() => {});
      }
    },
    addKlas: function () {
      this.klas = { Id: null, Naam: "", SchoolId: this.school.Id };
    },
    deleteKlas: function () {
      if (confirm("Weet je zeker dat je deze klas wit verwijderen?")) {
        this.$api()
          .pKlasDelete({ KlasId: this.klas.Id })
          .then(() => {
            this.klas = null;
            this.getklassen();
          })
          .catch(() => {});
      }
    },
    saveKlas: function () {
      this.$api()
        .pKlasSave({
          KlasId: this.klas.Id,
          SchoolId: this.school.Id,
          Naam: this.klas.Naam,
        })
        .then(() => {
          this.klas = null;
          this.getklassen();
        })
        .catch(() => {});
    },
  },
  watch: {
    school: function () {
      if (this.school != null) {
        this.$api()
          .pKlassenGet({ SchoolId: this.school.Id })
          .then((response) => {
            this.klassen = response.data;
            this.klas = null;
          })
          .catch(() => {});
      } else {
        this.klassen = [];
        this.klas = null;
      }
    },
    klas: function () {},
  },
  created: function () {
    this.getscholen();
  },
};
</script>


<style scoped>
</style>
