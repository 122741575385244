<template>
  <div>
    <h2>Aanmelden vragenlijst ({{ modus }})</h2>
    <v-stepper v-model="step" vertical>
      <v-stepper-step :complete="school != null" step="1">
        <span v-if="klas"
          >Gekozen school: {{ school.Naam }} - {{ klas.Naam }}</span
        >
        <span v-if="!klas">Selecteer je school en klas</span>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-select
          label="Kies een school uit de lijst"
          :items="scholen"
          v-model="school"
          item-value="Id"
          item-text="Naam"
          return-object
        >
        </v-select>
        <v-select
          label="Kies een klas uit de lijst"
          :items="klassen"
          v-model="klas"
          item-value="Id"
          item-text="Naam"
          return-object
          v-show="school"
          no-data-text="Geen klassen voor deze school"
        >
        </v-select>
      </v-stepper-content>

      <v-stepper-step :complete="icon != null" step="2">
        <span v-if="!icon">Kies een sticker</span>
        <v-row v-if="icon" no-gutters align="center"
          >Gekozen sticker:&nbsp;
          <v-img
            :src="'./icons/' + icon.Filename"
            max-width="30"
            max-height="30"
            contain
            :title="icon.Omschrijving"
          ></v-img
        ></v-row>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-data-iterator
          :items="icons"
          item-key="Id"
          hide-default-footer
          disable-pagination
          v-show="icon == null"
          no-data-text="Voor deze school kan nog geen nameting worden gedaan."
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.Omschrijving"
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
                <v-card min-height="120" @click="icon = item">
                  <v-card-title class="justify-center" v-show="false">
                    {{ item.Omschrijving }}
                  </v-card-title>
                  <v-card-text class="text-center">
                    <v-img
                      :src="'/icons/' + item.Filename"
                      max-width="100"
                      max-height="100"
                      contain
                      class="mx-auto"
                    ></v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

        <v-btn
          small
          @click="
            school = null;
            icon = null;
            step = 1;
          "
          class="mt-5"
          v-show="icon == null"
          ><v-icon left>mdi-arrow-left-circle </v-icon>Terug naar
          schoolkeuze</v-btn
        >
      </v-stepper-content>

      <v-stepper-step :complete="step == 4" step="3">
        <span v-show="modus == 'voormeting'">Overige gegevens</span
        ><span v-show="modus != 'voormeting'">Start vragenlijst</span>
      </v-stepper-step>
      <v-stepper-content step="3">
        <div v-show="modus == 'voormeting'">
          <v-select
            label="Hoe oud ben je?"
            :items="leeftijden"
            v-model="leeftijd"
            return-object
            :error="leeftijdvalidate.error"
            :error-messages="leeftijdvalidate.message"
          >
          </v-select>
          <v-radio-group
            label="Wat is je geslacht?"
            v-model="geslacht"
            :error="geslachtvalidate.error"
            :error-messages="geslachtvalidate.message"
          >
            <v-radio value="V" label="Vrouw"></v-radio>
            <v-radio value="M" label="Man"></v-radio>
            <v-radio value="X" label="Anders"></v-radio>
            <v-radio value="-" label="Wil ik niet zeggen"></v-radio>
          </v-radio-group>
        </div>
        <v-btn color="primary" @click="startsessie()" class="mt-2"
          >Start de vragenlijst
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>


<script>
export default {
  name: "startsessie",
  components: {},
  props: ["modus"],
  data() {
    return {
      scholen: [],
      school: null,
      klassen: [],
      klas: null,
      icons: [],
      icon: null,
      step: 1,
      leeftijden: [],
      leeftijd: null,
      geslacht: null,
      leeftijdvalidate: { error: false, message: "" },
      geslachtvalidate: { error: false, message: "" },
    };
  },
  methods: {
    getscholen: function () {
      this.$api()
        .pScholenGet()
        .then((response) => {
          this.scholen = response.data;
        });
    },
    geticons: function (klasId) {
      this.$api()
        .pIconsGet({
          KlasId: klasId,
          IsVoormeting: this.modus == "voormeting",
        })
        .then((response) => {
          this.icons = response.data;
        });
    },
    getleeftijden: function () {
      this.leeftijden = [];
      for (var i = 9; i <= 13; i++) this.leeftijden.push(i);
    },
    startsessie: function () {
      if (this.modus == "voormeting") {
        if (this.leeftijd == null || this.leeftijd == 0) {
          this.leeftijdvalidate.message =
            "Je hebt je leeftijd nog niet ingevuld.";
          this.leeftijdvalidate.error = true;
          return;
        }
        if (this.geslacht == null) {
          this.geslachtvalidate.message =
            "Je hebt je geslacht nog niet ingevuld.";
          this.geslachtvalidate.error = true;
          return;
        }
      }

      this.step = 4;
      this.$api()
        .pSessieStart({
          IconId: this.icon.Id,
          KlasId: this.klas.Id,
          Leeftijd: this.leeftijd,
          Geslacht: this.geslacht,
          Modus: this.modus,
          Functie: null,
        })
        .then((response) => {
          var sessiecode = response.data[0].SessieCode;
          if (sessiecode) {
            this.$router.push({
              name: "vragenlijst",
              params: { code: sessiecode },
            });
          }
        });
    },
  },
  watch: {
    klas: function () {
      if (this.klas != null) {
        this.step = 2;
        this.geticons(this.klas.Id);
      }
    },
    school: function () {
      if (this.school != null) {
        this.$api()
          .pKlassenGet({ SchoolId: this.school.Id })
          .then((response) => {
            this.klassen = response.data;
            this.klas = null;
          })
          .catch(() => {});
      } else {
        this.klassen = [];
        this.klas = null;
      }
    },
    icon: function () {
      if (this.icon != null) {
        // Controleer of icon nog beschikbaar is
        if (this.modus == "voormeting") {
          this.$api()
            .pControleerIcon({ IconId: this.icon.Id, KlasId: this.klas.Id })
            .then((response) => {
              if (!response.data[0].IconBeschikbaar) {
                this.icon = null;
                this.step = 2;
                this.geticons(this.school.Id);
                alert(
                  "Deze sticker is net door iemand anders gekozen. Kies een andere sticker."
                );
                return;
              }
            });
        }
        this.step = 3;
      }
    },
    leeftijd: function () {
      this.leeftijdvalidate.message = "";
      this.leeftijdvalidate.error = false;
    },
    geslacht: function () {
      this.geslachtvalidate.message = "";
      this.geslachtvalidate.error = false;
    },
  },
  created: function () {
    this.getscholen();
    this.getleeftijden();
  },
};
</script>


<style scoped>
</style>
